import React, { Component } from "react";
import Form from "./Form.jsx";
import "./../../assets/Contact.css";


class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Form/>
      </div>
    );
  }
}
export default Contact;
